import React, { useState } from 'react';
import { Button, ModalScrollableContainer, Col, DragNDropContext, DragNDropSection, DragNDropSectionHeader, DraggableItem, DraggableItemActions, DraggableItemTitle, Droparea, DynamicScrollableContainer, Input, Row, Tooltip, DragNDropUtils, Textarea, Label } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../../helpers/helpers';

const defaultGeneralAvailable = [
    { title: 'Analysis', id: 'item-1' }, { title: 'Branch', id: 'item-2' }, { title: 'CD Completed', id: 'item-3' }, { title: 'CD Required', id: 'item-4' },
    { title: 'CBA Completed', id: 'item-5' }, { title: 'CBA Required', id: 'item-6' }, { title: 'HDMA Reportable Closing Percent', id: 'item-7' }, { title: 'Competition', id: 'item-8' },
    { title: 'Create Date', id: 'item-9' }, { title: 'Days in Decisioning', id: 'item-10' }, { title: 'Days in Last Review', id: 'item-11' }, { title: 'General item 1', id: 'item-12' },
    { title: 'General item 2', id: 'item-13' }, { title: 'General item 3', id: 'item-14' }, { title: 'General item 4', id: 'item-15' }, { title: 'General item 5', id: 'item-16' }, { title: 'General item 6', id: 'item-17' }
];

const defaultGeneralAssigned = [
    { title: 'Accounts - Face Sheet', id: 'item-18' },
];

const AVAILABLE_SECTION_NAME = 'available';
const ASSIGNED_SECTION_NAME = 'assigned';


const DetailsTab = () => {

    const [dragStartEvent, setDragStartEvent] = useState(null);
    const [dragUpdateEvent, setDragUpdateEvent] = useState(null);
    const [availableItems, setAvailableItems] = useState(defaultGeneralAvailable.map(e => { return { ...e, type: AVAILABLE_SECTION_NAME } }));
    const [assignedItems, setAssignedItems] = useState(defaultGeneralAssigned.map(e => { return { ...e, type: ASSIGNED_SECTION_NAME } }));
    const [availableSearch, setAvailableSearch] = useState('');
    const [assignedSearch, setAssignedSearch] = useState('');


    const handleDragStart = (event: any) => {
        setDragStartEvent(event);
        setDragUpdateEvent(null);
    };

    const handleDragUpdate = (event: any) => {
        setDragStartEvent(null);
        setDragUpdateEvent(event);
    };

    const getList = (listId: string) => {
        if (listId === AVAILABLE_SECTION_NAME) {
            return availableItems;
        } else if (listId === ASSIGNED_SECTION_NAME) {
            return assignedItems;
        }
        return null;
    }

    const handleDragEnd = (result: any) => {
        const { source, destination } = result;
        if (!result.destination) {
            setDragStartEvent(null);
            setDragUpdateEvent(null);
            return;
        }

        if (source.droppableId === destination.droppableId) {
            if (source.droppableId === AVAILABLE_SECTION_NAME) {
                const items = DragNDropUtils.reorderList(
                    availableItems,
                    source.index,
                    destination.index
                );
                setAvailableItems(items);
            } else if (source.droppableId === ASSIGNED_SECTION_NAME) {
                const items = DragNDropUtils.reorderList(
                    assignedItems,
                    source.index,
                    destination.index
                );

                setAssignedItems(items);
            }
        } else {
            const result: any = DragNDropUtils.moveBetweenLists(
                getList(source.droppableId) || [],
                getList(destination.droppableId) || [],
                source.index,
                destination.index
            );

            if (source.droppableId === AVAILABLE_SECTION_NAME) {
                setAvailableItems(result[0])
                setAssignedItems(result[1])
            } else if (source.droppableId === ASSIGNED_SECTION_NAME) {
                setAvailableItems(result[1])
                setAssignedItems(result[0])
            }
        }

        setDragStartEvent(null);
        setDragUpdateEvent(null);
    }

    const moveAvailableItemToAssigned = (index: number) => {
        const assignedClone = Array.from(assignedItems);
        const availableClone = Array.from(availableItems);
        const [removed] = availableClone.splice(index, 1);
        assignedClone.push(removed);

        setAvailableItems(availableClone);
        setAssignedItems(assignedClone);
    }

    const returnItemToAvailable = (index: number) => {
        const assignedClone = Array.from(assignedItems);
        const availableClone = Array.from(availableItems);
        const [removed] = assignedClone.splice(index, 1);
        availableClone.push(removed);

        setAvailableItems(availableClone);
        setAssignedItems(assignedClone);
    }

    const addAllToAssigned = () => {
        let assignedClone = Array.from(assignedItems);
        let availableClone = Array.from(availableItems);
        assignedClone = [...assignedClone, ...availableClone];
        availableClone = [];

        setAvailableItems(availableClone);
        setAssignedItems(assignedClone);
    }

    const removeAllAssigned = () => {
        let availableClone = Array.from(availableItems);
        let assignedClone = Array.from(assignedItems);
        for (const item of assignedClone) {
            availableClone.push(item);
        }

        setAvailableItems(availableClone);
        setAssignedItems([]);
    }

    return (
        <ModalScrollableContainer>
            <Row className="mb-m">
                <Col xs={{ size: 6, offset: 3 }}>
                    <Row>
                        <Col xs="2" className="align-self-start mb-s">
                            <Label dataUI={getDataUI()} className="justify-content-end text-right">Name</Label>
                        </Col>
                        <Col xs="10" className="mb-s">
                            <Input dataUI={getDataUI()} value="3.0 Admin Testers" />
                        </Col>
                        <Col xs="2" className="align-self-start">
                            <Label dataUI={getDataUI()} className="justify-content-end text-right">Description</Label>
                        </Col>
                        <Col xs="10">
                            <Textarea dataUI={getDataUI()} value="This is a description." />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <h2 className="mb-m">Permissions</h2>
            <hr className="mb-m" />
            <DragNDropContext onDragStart={handleDragStart} onDragUpdate={handleDragUpdate} onDragEnd={handleDragEnd}>
                <Row>
                    <Col xs={6}>
                        <DynamicScrollableContainer dataUI={getDataUI()}>
                            <DragNDropSection dataUI={getDataUI()}>
                                <DragNDropSectionHeader dataUI={getDataUI()}>
                                    <div className="d-flex justify-content-between align-items-center mb-m">
                                        <h2>Available Permissions ({availableItems.length})</h2>
                                        <Button dataUI={getDataUI()} onClick={addAllToAssigned} btnType="secondary" icon="fal fa-file-import">Add all</Button>
                                    </div>
                                    <Input
                                        dataUI={getDataUI()}
                                        name="input-icon"
                                        value={availableSearch}
                                        placeholder="Search"
                                        onChange={(ev) => setAvailableSearch(ev.target.value)}
                                        icon='fal fa-search'
                                    />
                                </DragNDropSectionHeader>
                                <Droparea dataUI={getDataUI()} dropareaID={AVAILABLE_SECTION_NAME} allowedItems={[AVAILABLE_SECTION_NAME, ASSIGNED_SECTION_NAME]}
                                    dragStartEvent={dragStartEvent}
                                    dragUpdateEvent={dragUpdateEvent}
                                >

                                    {availableItems.map((item, index) => {
                                        return (
                                            !availableSearch || (availableSearch && item.title.includes(availableSearch)) ?
                                                <DraggableItem dataUI={getDataUI()} key={item.title} index={index} draggableID={item.id}>
                                                    <DraggableItemActions dataUI={getDataUI()}>
                                                        <DraggableItemTitle dataUI={getDataUI()}>{item.title}</DraggableItemTitle>
                                                    </DraggableItemActions>
                                                    <DraggableItemActions dataUI={getDataUI()}>
                                                        <Tooltip title="Move">
                                                            <Button dataUI={getDataUI()} onClick={() => moveAvailableItemToAssigned(index)} btnType="icon" icon="fal fa-arrow-right" />
                                                        </Tooltip>
                                                    </DraggableItemActions>
                                                </DraggableItem>
                                                :
                                                ''
                                        )
                                    })}
                                </Droparea>
                            </DragNDropSection>
                        </DynamicScrollableContainer>
                    </Col>

                    <Col xs={6}>
                        <DynamicScrollableContainer dataUI={getDataUI()}>
                            <DragNDropSection dataUI={getDataUI()}>
                                <DragNDropSectionHeader dataUI={getDataUI()}>
                                    <div className="d-flex justify-content-between align-items-center mb-m">
                                        <h2>Assigned Permissions ({assignedItems.length})</h2>
                                        <Button dataUI={getDataUI()} onClick={removeAllAssigned} btnType="secondary" icon="fal fa-file-import">Remove all</Button>
                                    </div>
                                    <Input
                                        dataUI={getDataUI()}
                                        name="input-icon"
                                        value={assignedSearch}
                                        placeholder="Search"
                                        onChange={(ev) => setAssignedSearch(ev.target.value)}
                                        icon='fal fa-search'
                                    />
                                </DragNDropSectionHeader>
                                <Droparea
                                    dataUI={getDataUI()}
                                    dropareaID={ASSIGNED_SECTION_NAME}
                                    allowedItems={[ASSIGNED_SECTION_NAME, AVAILABLE_SECTION_NAME]}
                                    callToActionText="Drop items here."
                                    dragStartEvent={dragStartEvent}
                                    dragUpdateEvent={dragUpdateEvent}
                                >
                                    {assignedItems.map((item, index) => {
                                        return (
                                            !assignedSearch || (assignedSearch && item.title.includes(assignedSearch)) ?
                                                <DraggableItem dataUI={getDataUI()} key={item.title} index={index} draggableID={item.id}>
                                                    <DraggableItemActions dataUI={getDataUI()}>
                                                        <DraggableItemTitle dataUI={getDataUI()}>{item.title}</DraggableItemTitle>
                                                    </DraggableItemActions>
                                                    <DraggableItemActions dataUI={getDataUI()}>
                                                        <Tooltip title="Remove">
                                                            <Button dataUI={getDataUI()} onClick={() => returnItemToAvailable(index)} btnType="icon" icon="fal fa-times" />
                                                        </Tooltip>
                                                    </DraggableItemActions>
                                                </DraggableItem>
                                                :
                                                ''
                                        )
                                    })}
                                </Droparea>
                            </DragNDropSection>
                        </DynamicScrollableContainer>
                    </Col>
                </Row>
            </DragNDropContext>
        </ModalScrollableContainer>
    );
};

export default DetailsTab;