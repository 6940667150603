import React, { useState } from 'react';
import { Button } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';
import EditRolesModal from './EditRoles/EditRolesModal';
import EditTeamsModal from './EditTeams/EditTeamsModal';
import EditUsersModal from './EditUsers/EditUsersModal';


const RolesTeamsUsersPage = () => {
    const [isRolesModalVisible, setIsRolesModalVisible] = useState(false);
    const [isTeamsModalVisible, setIsTeamsModalVisible] = useState(false);
    const [isUsersModalVisible, setIsUsersModalVisible] = useState(false);

    return (
        <>
            <Button className="width-l mb-s" onClick={() => setIsRolesModalVisible(true)} dataUI={getDataUI()}>Roles Modal</Button>
            <Button className="width-l mb-s" onClick={() => setIsTeamsModalVisible(true)} dataUI={getDataUI()}>Teams Modal</Button>
            <Button className="width-l" onClick={() => setIsUsersModalVisible(true)} dataUI={getDataUI()}>Users Modal</Button>

            <EditRolesModal isVisible={isRolesModalVisible} onClose={() => setIsRolesModalVisible(false)} />
            <EditTeamsModal isVisible={isTeamsModalVisible} onClose={() => setIsTeamsModalVisible(false)} />
            <EditUsersModal isVisible={isUsersModalVisible} onClose={() => setIsUsersModalVisible(false)} />
        </>
    );
};

export default RolesTeamsUsersPage;