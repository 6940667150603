import React, { useEffect, useState } from 'react';
import { Button, ModalScrollableContainer, Col, DragNDropContext, DragNDropSection, DragNDropSectionHeader, DraggableItem, DraggableItemActions, DraggableItemTitle, Droparea, DynamicScrollableContainer, Input, Row, Tooltip, DragNDropUtils, Toolbar, LinkWithIcon, Collapse, TreeView } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../../helpers/helpers';

const defaultGeneralAvailable = [
    { title: 'Team 1', id: 'item-1', users: [{ title: 'Pesho', }] },
    { title: 'Team 2', id: 'item-2', users: [{ title: 'Pesho' }, { title: 'Gosho' }] },
    { title: 'Team 3', id: 'item-3', users: [{ title: 'Pesho' }, { title: 'Gosho' }] },
    { title: 'Team 4', id: 'item-4', users: [{ title: 'Pesho' }, { title: 'Gosho' }] },
    { title: 'Team 5', id: 'item-5', users: [{ title: 'Pesho' }, { title: 'Gosho' }] },
    { title: 'Team 6', id: 'item-6', users: [{ title: 'Pesho' }, { title: 'Gosho' }] },
    { title: 'Team 7', id: 'item-7', users: [{ title: 'Pesho' }, { title: 'Gosho' }] },
    { title: 'Team 8', id: 'item-8', users: [{ title: 'Pesho' }, { title: 'Gosho' }] },
    { title: 'Team 9', id: 'item-9', users: [{ title: 'Pesho' }, { title: 'Gosho' }] },
    { title: 'Team 10', id: 'item-10', users: [{ title: 'Pesho' }, { title: 'Gosho' }] },
    { title: 'General item 1', id: 'item-11', users: [{ title: 'Pesho' }, { title: 'Gosho' }] },
    { title: 'General item 2', id: 'item-12', users: [{ title: 'Pesho' }, { title: 'Gosho' }] },
    { title: 'General item 3', id: 'item-13', users: [{ title: 'Pesho' }, { title: 'Gosho' }] },
    { title: 'General item 4', id: 'item-14', users: [{ title: 'Pesho' }, { title: 'Gosho' }] },
    { title: 'General item 5', id: 'item-15', users: [{ title: 'Pesho' }, { title: 'Gosho' }] },
    { title: 'General item 6', id: 'item-16', users: [{ title: 'Pesho' }, { title: 'Gosho' }] },
    { title: 'General item 7', id: 'item-17', users: [{ title: 'Pesho' }, { title: 'Gosho' }] }
];

const defaultGeneralAssigned: {
    title: string;
    id: string;
    users: {
        title: string;
        icon?: string;
    }[];
}[] = [
        {
            title: '3.0 Admin Testers', id: 'item-18', users: [
                {
                    title: 'Permission 1',
                    icon: 'fal fa-shield-alt',
                },
                {
                    title: 'Permission 2',
                    icon: 'fal fa-shield-alt',
                },
                {
                    title: 'Permission 3',
                    icon: 'fal fa-shield-alt',
                },
            ]
        },
        {
            title: 'Role Admin', id: 'item-19', users: [
                {
                    title: 'Permission 4',
                    icon: 'fal fa-shield-alt',
                },
                {
                    title: 'Permission 2',
                    icon: 'fal fa-shield-alt',
                },
                {
                    title: 'Permission 6',
                    icon: 'fal fa-shield-alt',
                },
            ]
        },
    ];

const AVAILABLE_SECTION_NAME = 'available';
const ASSIGNED_SECTION_NAME = 'assigned';


const RoleAssignmentTab = () => {

    const [dragStartEvent, setDragStartEvent] = useState(null);
    const [dragUpdateEvent, setDragUpdateEvent] = useState(null);
    const [availableItems, setAvailableItems] = useState(defaultGeneralAvailable.map(e => { return { ...e, type: AVAILABLE_SECTION_NAME } }));
    const [assignedItems, setAssignedItems] = useState(defaultGeneralAssigned.map(e => { return { ...e, type: ASSIGNED_SECTION_NAME } }));
    const [assignedUsersItems, setAssignedUsersItems] = useState(assignedItems.map(e => { return { ...e, expanded: false } }));
    const [availableSearch, setAvailableSearch] = useState('');
    const [assignedSearch, setAssignedSearch] = useState('');
    const [userSearch, setUserSearch] = useState('');


    useEffect(() => {
        setUserSearch('');
        setAssignedUsersItems(assignedItems.map(e => { return { ...e, expanded: false } }));
    }, [assignedItems])

    const isExpandedAll = () => {
        return !assignedUsersItems.some((e) => e.expanded === false);
    }

    const handleExpandAll = () => {
        setAssignedUsersItems(assignedItems.map(e => { return { ...e, expanded: true } }));
    }

    const handleCollapseAll = () => {
        setAssignedUsersItems(assignedItems.map(e => { return { ...e, expanded: false } }));
    }

    const handleDragStart = (event: any) => {
        setDragStartEvent(event);
        setDragUpdateEvent(null);
    };

    const handleDragUpdate = (event: any) => {
        setDragStartEvent(null);
        setDragUpdateEvent(event);
    };

    const getList = (listId: string) => {
        if (listId === AVAILABLE_SECTION_NAME) {
            return availableItems;
        } else if (listId === ASSIGNED_SECTION_NAME) {
            return assignedItems;
        }
        return null;
    }

    const handleDragEnd = (result: any) => {
        const { source, destination } = result;
        if (!result.destination) {
            setDragStartEvent(null);
            setDragUpdateEvent(null);
            return;
        }

        if (source.droppableId === destination.droppableId) {
            if (source.droppableId === AVAILABLE_SECTION_NAME) {
                const items = DragNDropUtils.reorderList(
                    availableItems,
                    source.index,
                    destination.index
                );
                setAvailableItems(items);
            } else if (source.droppableId === ASSIGNED_SECTION_NAME) {
                const items = DragNDropUtils.reorderList(
                    assignedItems,
                    source.index,
                    destination.index
                );

                setAssignedItems(items);
            }
        } else {
            const result: any = DragNDropUtils.moveBetweenLists(
                getList(source.droppableId) || [],
                getList(destination.droppableId) || [],
                source.index,
                destination.index
            );

            if (source.droppableId === AVAILABLE_SECTION_NAME) {
                setAvailableItems(result[0])
                setAssignedItems(result[1])
            } else if (source.droppableId === ASSIGNED_SECTION_NAME) {
                setAvailableItems(result[1])
                setAssignedItems(result[0])
            }
        }

        setDragStartEvent(null);
        setDragUpdateEvent(null);
    }

    const moveAvailableItemToAssigned = (index: number) => {
        const assignedClone = Array.from(assignedItems);
        const availableClone = Array.from(availableItems);
        const [removed] = availableClone.splice(index, 1);
        assignedClone.push(removed);

        setAvailableItems(availableClone);
        setAssignedItems(assignedClone);
    }

    const returnItemToAvailable = (index: number) => {
        const assignedClone = Array.from(assignedItems);
        const availableClone = Array.from(availableItems);
        const [removed] = assignedClone.splice(index, 1);
        availableClone.push(removed);

        setAvailableItems(availableClone);
        setAssignedItems(assignedClone);
    }

    const addAllToAssigned = () => {
        let assignedClone = Array.from(assignedItems);
        let availableClone = Array.from(availableItems);
        assignedClone = [...assignedClone, ...availableClone];
        availableClone = [];

        setAvailableItems(availableClone);
        setAssignedItems(assignedClone);
    }

    const removeAllAssigned = () => {
        let availableClone = Array.from(availableItems);
        let assignedClone = Array.from(assignedItems);
        for (const item of assignedClone) {
            availableClone.push(item);
        }

        setAvailableItems(availableClone);
        setAssignedItems([]);
    }

    const toggleExpand = (id: string) => {
        const updatedData = assignedUsersItems.map((item) => {
            if (item.id === id) {
                return { ...item, expanded: !item.expanded };
            }
            return item;
        });

        setAssignedUsersItems(updatedData);
    }

    const filterByUserAndExpand = (searchName: string) => {

        if (searchName === '') {
            const updatedData = assignedItems.map((item) => {
                const currentIndex = assignedUsersItems.findIndex(u => u.id === item.id);

                return {
                    ...item,
                    expanded: currentIndex > -1 ? assignedUsersItems[currentIndex].expanded : false
                }
            });

            setAssignedUsersItems(updatedData)
            return;
        }

        const renderData: any[] = [];

        assignedItems.forEach((item) => {

            let filteredUsers = item.users.filter((user) => {
                return user.title.toLowerCase().includes(searchName.toLowerCase())
            });

            const userMatches = filteredUsers.length > 0;

            if (userMatches) {
                filteredUsers = filteredUsers.map((user) => {
                    return { ...user, titleElement: renderMatchingNames(user.title, searchName) }
                })

                renderData.push({ ...item, users: filteredUsers, expanded: userMatches })
            }
        });

        setAssignedUsersItems(renderData);
    };

    const renderMatchingNames = (name: string, searchName: string) => {
        const startIndex = name.toLowerCase().indexOf(searchName.toLowerCase());
        if (startIndex === -1) {
            return name;
        }

        const endIndex = startIndex + searchName.length;

        return (
            <>
                {name.substring(0, startIndex)}
                <b>{name.substring(startIndex, endIndex)}</b>
                {name.substring(endIndex)}
            </>
        );
    };

    return (
        <ModalScrollableContainer>
            <DragNDropContext onDragStart={handleDragStart} onDragUpdate={handleDragUpdate} onDragEnd={handleDragEnd}>
                <Row>
                    <Col xs={4}>
                        <DynamicScrollableContainer dataUI={getDataUI()}>
                            <DragNDropSection dataUI={getDataUI()}>
                                <DragNDropSectionHeader dataUI={getDataUI()}>
                                    <div className="d-flex justify-content-between align-items-center mb-m">
                                        <h2>Available Roles ({availableItems.length})</h2>
                                        <Button dataUI={getDataUI()} onClick={addAllToAssigned} btnType="secondary" icon="fal fa-file-import">Add all</Button>
                                    </div>
                                    <Input
                                        dataUI={getDataUI()}
                                        name="input-icon"
                                        value={availableSearch}
                                        placeholder="Search"
                                        onChange={(ev) => setAvailableSearch(ev.target.value)}
                                        icon='fal fa-search'
                                    />
                                </DragNDropSectionHeader>
                                <Droparea dataUI={getDataUI()} dropareaID={AVAILABLE_SECTION_NAME} allowedItems={[AVAILABLE_SECTION_NAME, ASSIGNED_SECTION_NAME]}
                                    dragStartEvent={dragStartEvent}
                                    dragUpdateEvent={dragUpdateEvent}
                                >

                                    {availableItems.map((item, index) => {
                                        return (
                                            !availableSearch || (availableSearch && item.title.includes(availableSearch)) ?
                                                <DraggableItem dataUI={getDataUI()} key={item.title} index={index} draggableID={item.id}>
                                                    <DraggableItemActions dataUI={getDataUI()}>
                                                        <DraggableItemTitle dataUI={getDataUI()}>{item.title}</DraggableItemTitle>
                                                    </DraggableItemActions>
                                                    <DraggableItemActions dataUI={getDataUI()}>
                                                        <Tooltip title="Move">
                                                            <Button dataUI={getDataUI()} onClick={() => moveAvailableItemToAssigned(index)} btnType="icon" icon="fal fa-arrow-right" />
                                                        </Tooltip>
                                                    </DraggableItemActions>
                                                </DraggableItem>
                                                :
                                                ''
                                        )
                                    })}
                                </Droparea>
                            </DragNDropSection>
                        </DynamicScrollableContainer>
                    </Col>

                    <Col xs={4}>
                        <DynamicScrollableContainer dataUI={getDataUI()}>
                            <DragNDropSection dataUI={getDataUI()}>
                                <DragNDropSectionHeader dataUI={getDataUI()}>
                                    <div className="d-flex justify-content-between align-items-center mb-m">
                                        <h2>Assigned Roles ({assignedItems.length})</h2>
                                        <Button dataUI={getDataUI()} onClick={removeAllAssigned} btnType="secondary" icon="fal fa-file-import">Remove all</Button>
                                    </div>
                                    <Input
                                        dataUI={getDataUI()}
                                        name="input-icon"
                                        value={assignedSearch}
                                        placeholder="Search"
                                        onChange={(ev) => setAssignedSearch(ev.target.value)}
                                        icon='fal fa-search'
                                    />
                                </DragNDropSectionHeader>
                                <Droparea
                                    dataUI={getDataUI()}
                                    dropareaID={ASSIGNED_SECTION_NAME}
                                    allowedItems={[ASSIGNED_SECTION_NAME, AVAILABLE_SECTION_NAME]}
                                    callToActionText="Drop items here."
                                    dragStartEvent={dragStartEvent}
                                    dragUpdateEvent={dragUpdateEvent}
                                >
                                    {assignedItems.map((item, index) => {
                                        return (
                                            !assignedSearch || (assignedSearch && item.title.includes(assignedSearch)) ?
                                                <DraggableItem dataUI={getDataUI()} key={item.title} index={index} draggableID={item.id}>
                                                    <DraggableItemActions dataUI={getDataUI()}>
                                                        <DraggableItemTitle dataUI={getDataUI()}>{item.title}</DraggableItemTitle>
                                                    </DraggableItemActions>
                                                    <DraggableItemActions dataUI={getDataUI()}>
                                                        <Tooltip title="Remove">
                                                            <Button dataUI={getDataUI()} onClick={() => returnItemToAvailable(index)} btnType="icon" icon="fal fa-times" />
                                                        </Tooltip>
                                                    </DraggableItemActions>
                                                </DraggableItem>
                                                :
                                                ''
                                        )
                                    })}
                                </Droparea>
                            </DragNDropSection>
                        </DynamicScrollableContainer>
                    </Col>
                    <Col xs={4}>
                        <div className="section-container height-full">
                            <h2 className="mb-m">Permissions of the assigned Roles</h2>
                            <hr className="mb-m" />
                            <Toolbar
                                dataUI={getDataUI()}
                                className="mb-m"
                                leftSide={
                                    <LinkWithIcon dataUI={getDataUI()} className="mr-m" icon={isExpandedAll() ? 'fal fa-compress-alt' : "fal fa-expand-alt"} iconPlace="left">
                                        <a href="/" onClick={(e) => {
                                            e.preventDefault();

                                            if (isExpandedAll()) {
                                                handleCollapseAll();
                                                return;
                                            }

                                            handleExpandAll();
                                        }}>
                                            {isExpandedAll() ? 'Collapse all' : 'Expand all'}
                                        </a>
                                    </LinkWithIcon>
                                }
                                rightSide={
                                    <Input
                                        dataUI={getDataUI()}
                                        name="input-icon"
                                        value={userSearch}
                                        placeholder="Search permissions"
                                        onChange={(ev) => {
                                            setUserSearch(ev.target.value);
                                            filterByUserAndExpand(ev.target.value);
                                        }}
                                        icon='fal fa-search'
                                    />
                                }
                            />
                            {assignedUsersItems.map((item) => {
                                return (
                                    <Collapse
                                        dataUI={getDataUI()}
                                        key={item.title}
                                        title={item.title}
                                        rightIcon="fal fa-id-card"
                                        expanded={item.expanded}
                                        smallLeftPadding
                                        onClick={() => toggleExpand(item.id)}
                                    >
                                        <div className="background-color-2 p-m">
                                            <TreeView items={item.users} dataUI={getDataUI()} />
                                        </div>
                                    </Collapse>
                                )
                            })}
                        </div>
                    </Col>
                </Row>
            </DragNDropContext>
        </ModalScrollableContainer>
    );
};

export default RoleAssignmentTab;